import { SortByPipe } from './sort-by.pipe';
import { SumPipe } from './sum.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog.component';
import { MatButtonModule, MatDialogModule } from '@angular/material';



@NgModule({
  declarations: [
    SumPipe,
    SortByPipe,
    ConfirmDialog
  ],
  entryComponents: [
    ConfirmDialog
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [
    SumPipe,
    SortByPipe
  ]
})
export class SharedModule { }
