import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
  })
  export class ConfirmDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  }
  export interface DialogData {
    text: string;
  }