import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Chart } from "angular-highcharts";
import { Point, Series, SeriesColumnOptions } from "highcharts";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { ConfirmDialog } from "./shared/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit {
  title = "test-score-calculator";
  maxPoints: number;
  testScores: TestScore[];
  cols: any[];
  results: number[];
  resultToAdd: number;
  groupedResults: any[];
  gradeResults: number[];
  chart: Chart;
  displayedColumns: string[] = ["points", "name"];
  groupInfo: any;
  quickAddSelectedGroup: string;
  appVersion: string = environment.appVersion;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  calculate() {
    this.testScores = [];
    this.results = [];

    this.cols = new Array(Math.ceil((this.maxPoints + 1) / 10));

    let grade = 10;
    let points = this.maxPoints;
    this.testScores.push(new TestScore(points, grade));

    while (points > 1) {
      points--;
      grade = this.round((9 / this.maxPoints) * points + 1, 1);
      this.testScores.push(new TestScore(points, grade));
    }

    this.testScores.push(new TestScore(0, 1));
    this.quickAddSelectedGroup = this.testScores[0].groupResults[0].name;
    this.clearGroupResults();
  }

  reset() {
    this.cols = null;
    this.maxPoints = null;
    this.testScores = null;
    this.results = [];
  }

  updateChart() {
    this.buildChart();
  }

  max(...params: number[]) {
    return Math.max(...params);
  }

  round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  addResult(testScore: GroupResult) {
    testScore.amountResults++;
    this.updateChart();
  }

  removeResult(testScore: GroupResult) {
    if (testScore.amountResults > 0) {
      testScore.amountResults--;
      this.updateChart();
    }
  }

  addResultManually() {
    if(!this.resultToAdd) {
      return;
    }

    if (this.resultToAdd <= this.maxPoints) {
      var score = this.testScores.find((ts) => ts.points === this.resultToAdd);
      var groupResults = score.groupResults.find(gr => gr.name == this.quickAddSelectedGroup);
      groupResults.amountResults++;
      this.resultToAdd = null;
      this.updateChart();
    }
  }

  addGroup() {
    var newGroupName = `Groep ${this.testScores[0].groupResults.length + 1}`;
    this.testScores.forEach((ts) => {
      var newGroup = new GroupResult(newGroupName);
      ts.groupResults.push(newGroup);
    });
  }

  deleteGroup(group: GroupResult) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        text: `Weet u zeker dat u de groep <b>'${group.name}'</b> wilt verwijderen?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.testScores.forEach((ts) => {
          var index = ts.groupResults.findIndex((g) => g.name == group.name);
          ts.groupResults.splice(index, 1);
        });
      }
    });
  }

  changeGroupName(event, group: GroupResult) {
    var oldName = group.name;

    this.testScores.forEach((ts) => {
      var g = ts.groupResults.find((g) => g.name === oldName);
      g.name = event;
    });
  }

  calculateGrouped() {
    this.groupedResults = this.groupBy(this.results, null);

    this.gradeResults = [];
    this.results.forEach((r) => {
      this.gradeResults.push(
        this.testScores.find((ts) => ts.points === r).grade
      );
    });
  }

  clearGroupResults() {
    this.testScores.forEach((ts) =>
      ts.groupResults.forEach((gr) => (gr.amountResults = 0))
    );
    this.buildChart();
  }

  buildChart() {
    var categories = [];
    var data = [];
    let workSet = this.testScores.slice(0);
    workSet.sort((a, b) => {
      return a.points - b.points;
    });

    this.groupInfo = [];
    
    var series = workSet[0].groupResults.map((g, i) => {
      var serie = {} as SeriesColumnOptions;
      var totalResults = 0;
      var totalPoints = 0;
      var totalGrade = 0;
      serie.name = g.name;
      serie.data = workSet.map((ts) => {
        totalResults += ts.groupResults[i].amountResults;
        totalPoints += ts.groupResults[i].amountResults * ts.points;
        totalGrade += ts.groupResults[i].amountResults * ts.grade;
        return ts.groupResults[i].amountResults;
      });

      this.groupInfo.push({
        name: serie.name,
        totalResults: totalResults,
        totalPoints: totalPoints,
        totalGrade: totalGrade
      });
      
      return serie;
    });

    workSet.forEach((ts) => {
      categories.push(ts.points);
      data.push(ts.amountResults);
    });

    let chart = new Chart({
      chart: {
        type: "column",
      },
      title: {
        text: "Resultaten",
      },
      credits: {
        enabled: true,
      },
      xAxis: {
        tickWidth: 0,
        lineWidth: 3,
        tickInterval: 5,
        title: {
          text: "Aantal behaalde punten",
        },
        opposite: false,
        categories: categories,
      },
      yAxis: {
        tickInterval: 5,
        min: 0,
        max: 30,
        lineWidth: 0,
        opposite: true,
        labels: {
          format: "{value}",
        },
        title: {
          text: "Aantal leerlingen",
        },
      },
      tooltip: {
        headerFormat:
          '<span style="font-size:14px">{point.key} pnt.</span><table>',
        pointFormat:
          "<tr>" +
          '   <td style="color:{series.color};padding:0">{series.name}: </td>' +
          '   <td style="padding:0;text-align:right"><b>{point.y}</b></td>' +
          "</tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
          borderWidth: 2,
        },
      },
      series: series,
    });
    this.chart = chart;
  }

  private groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      if (key && x == Object(x)) {
        // Complex type
        (rv[x[key]] = rv[x[key]] || []).push(x);
      } else {
        // Simple type
        let y = x.toString();
        (rv[y] = rv[y] || []).push(x);
      }
      return rv;
    }, {});
  }
}

export class TestScore {
  points: number;
  grade: number;
  amountResults: number;
  groupResults: GroupResult[];

  constructor(points: number, grade: number) {
    this.points = points;
    this.grade = grade;
    this.amountResults = 0;
    this.groupResults = [];

    let defaultGroup = new GroupResult("Groep 1");
    this.groupResults.push(defaultGroup);
  }
}

export class GroupResult {
  name: string;
  amountResults: number;

  constructor(name: string) {
    this.name = name;
    this.amountResults = 0;
  }
}
