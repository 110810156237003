import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'sum',
  pure: false
})
export class SumPipe implements PipeTransform {
  transform(items: any[], attr: string): any {
    if(!items) {
      return 0;
    }

    if (attr) {
      return items.reduce((a, b) => a + b[attr], 0);
    } else {
      return items.reduce((a, b) => a + b, 0);
    }
  }
}
